<!--
 * @version: 1.0
 * @Date: 2023-04-20 18:02:42
 * @LastEditors: 杨慢慢
-->
<template>
  <div class="process-box">
    <div class="design" :style="'transform: scale(' + scale / 100 + ');'">
      <process-tree ref="process-tree" @selectedNode="nodeSelected" />
    </div>
    <div class="drawer">
      <a-drawer
        :title="null"
        v-model:visible="showConfig"
        :width="selectedNode.type === 'ROOT' || selectedNode.type === 'END' ? 356 : 444"
        placement="right"
        :closable="false"
        :maskStyle="{ opacity: '0', animation: 'none' }"
        :bodyStyle="{ padding: '0px' }"
        :wrapStyle="{ paddingTop: '115px' }"
      >
        <div class="node-config-content">
          <NodeConfig :headBgc="headBgc" @cancel="handleCancel" @ok="handleOk" :showConfig="showConfig" />
        </div>
      </a-drawer>
    </div>
  </div>
</template>

<script>
import ProcessTree from '@/views/flowable/process/ProcessTree.vue'
import NodeConfig from '@/views/flowable/common/process/config/NodeConfig.vue'

export default {
  name: 'flowPath',
  components: { ProcessTree, NodeConfig },
  data() {
    return {
      scale: 100,
      selected: {},
      showInput: false,
      showConfig: false,
      headBgc: ''
    }
  },
  computed: {
    selectedNode() {
      return this.$store.state.process.selectedNode
    },
    process() {
      return this.$store.state.process.design.process
    }
  },
  methods: {
    nodeSelected(node) {
      console.log('配置节点', node)
      if (node.name === '默认条件') return
      this.showConfig = true
      // 背景色
      this.headBgc =
        this.selectedNode.type === 'ROOT'
          ? 'linear-gradient(90deg, #E9393F 0%, #ED9DA0 100%)'
          : this.selectedNode.type === 'APPROVAL'
          ? 'linear-gradient(90deg, #FF9535 0%, #FFC452 100%)'
          : this.selectedNode.type === 'CONDITION'
          ? 'linear-gradient(90deg, #60C7BC 0%, #00D3BE 100%)'
          : 'linear-gradient(90deg, #A5B1CC 0%, #D1D9E8 100%)'
    },
    handleCancel(value) {
      this.showConfig = false
    },
    handleOk(value) {
      this.showConfig = value
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>
